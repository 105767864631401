<template>
  <div class="col-12">
    <!-- right-body -->
    <div class="">
      <!-- call-area -->
      <div class="call-area">
        <!-- call-setting -->
        <div class="call-setting">
          <div class="histoty-timeline text-right">
            <div class="timeline">
              <!-- <a class="history-export" href="#"
                >Export
                <img class="img-fluid" src="../../assets/img/export.svg" alt=""
              /></a> -->
              <button
                type="button"
                v-bind:class="filterSearch.duration == 'today' ? 'current' : ''"
                v-on:click="updateData('today')"
              >
                <span class="today">Today</span>
              </button>
              <button
                type="button"
                v-bind:class="filterSearch.duration == 'month' ? 'current' : ''"
                v-on:click="updateData('month')"
              >
                <span class="today">This month</span>
              </button>
              <button
                type="button"
                v-bind:class="
                  filterSearch.duration == 'custom' ? 'current' : ''
                "
              >
                <date-range-picker
                  :opens="'left'"
                  :ranges="false"
                  :locale-data="{ format: 'yyyy-mm-dd' }"
                  v-model="filterSearch.customDate"
                  @update="updateData('custom')"
                ></date-range-picker
                ><span class="month">Custom Day</span>
              </button>
            </div>
          </div>

          <!-- call-box -->
          <div class="call-setting">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="Facebook-tab"
                  data-toggle="tab"
                  href="#Facebook"
                  role="tab"
                  aria-controls="Facebook"
                  aria-selected="true"
                  >Facebook</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="Google-tab"
                  data-toggle="tab"
                  href="#Google"
                  role="tab"
                  aria-controls="Google"
                  aria-selected="false"
                  >Google</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="Sales-tab"
                  data-toggle="tab"
                  href="#Sales"
                  role="tab"
                  aria-controls="Sales"
                  aria-selected="false"
                  >Website</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="Facebook"
                role="tabpanel"
                aria-labelledby="Facebook-tab"
              >
                <!-- leads-table-area  -->

                <div class="leads-table-area history-table addcustomer-table">
                  <table class="display" id="facebook">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Sms From</th>
                        <th>Sms To</th>
                        <th>Sms Type</th>
                        <th style="width: 70px"></th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>

                <!-- leads-table-area -->
              </div>

              <div
                class="tab-pane fade"
                id="Google"
                role="tabpanel"
                aria-labelledby="Google-tab"
              >
                <!-- leads-table-area  -->
                <div class="leads-table-area history-table addcustomer-table">
                  <table class="display" id="google">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Sms From</th>
                        <th>Sms To</th>
                        <th>Sms Type</th>
                        <th style="width: 70px"></th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
                <!-- leads-table-area  -->
              </div>

              <div
                class="tab-pane fade"
                id="Sales"
                role="tabpanel"
                aria-labelledby="Sales-tab"
              >
                <!-- leads-table-area  -->
                <div class="leads-table-area history-table addcustomer-table">
                  <table class="display" id="websitesales">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Sms From</th>
                        <th>Sms To</th>
                        <th>Sms Type</th>
                        <th style="width: 70px"></th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
                <!-- leads-table-area  -->
              </div>
            </div>
          </div>
          <!-- call-box -->
        </div>
        <!-- call-area -->
      </div>
    </div>
    <!-- Detail   Modal -->
    <div
      class="modal fade"
      id="logdetailmodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Message Body</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <p>{{ detail.message }}</p>
                <br /><br />
                <img
                  class="img-fluid"
                  style="width: 200px"
                  v-if="detail.image"
                  :src="detail.image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Detail  Modal -->
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      filterSearch: {
        duration: "month",
        agent: "",
        team: "",
        customDate: {},
        from: "",
        to: "",
      },
      detail: {},
      callhistory: [],
    };
  },
  created() {
    this.getDealerDetail();
    //this.getCallHistory();
  },

  methods: {
    updateData(val) {
      if (val == "custom") {
        let startDate = this.filterSearch.customDate.startDate;
        let endDate = this.filterSearch.customDate.endDate;
        this.filterSearch.from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        this.filterSearch.to = moment(new Date(endDate)).format("YYYY-MM-DD");
        this.filterSearch.duration = "custom";
      } else {
        this.filterSearch.duration = val;
      }
      $("#facebook").dataTable().fnDraw();
      $("#google").dataTable().fnDraw();
      $("#websiteservice").dataTable().fnDraw();
      $("#websitesales").dataTable().fnDraw();
    },

    getDealerDetail() {
      axios
        .get("get_dealer_detail", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.dealer_detail = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMessageDeatils(id) {
      axios
        .get("getsmslogdetail/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.detail = res.data.detail;
            $("#logdetailmodal").modal("show");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    let v = this;
    $("#facebook")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        order: [[0, "desc"]],
        ajax: {
          url: axios.defaults.baseURL + "getSmshistory",
          type: "POST",
          data: function (d) {
            d.duration = v.filterSearch.duration;
            d.from = v.filterSearch.from;
            d.to = v.filterSearch.to;
          },
          headers: {
            Authorization: "Bearer " + v.$storage.get("auth").token,
          },
        },
        columns: [
          {
            data: "date",
          },
          {
            data: "time",
          },
          {
            data: "sms_from",
          },
          {
            data: "sms_to",
          },
          {
            data: "sms_type",
          },
          {
            data: "action_link",
          },
        ],
        columnDefs: [{ targets: [5], orderable: false }],
      });
    $("#google")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        order: [[0, "desc"]],
        ajax: {
          url: axios.defaults.baseURL + "getsmsgooglehistory",
          type: "POST",
          data: function (d) {
            d.duration = v.filterSearch.duration;
            d.from = v.filterSearch.from;
            d.to = v.filterSearch.to;
          },
          headers: {
            Authorization: "Bearer " + v.$storage.get("auth").token,
          },
        },
        columns: [
          {
            data: "date",
          },
          {
            data: "time",
          },
          {
            data: "sms_from",
          },
          {
            data: "sms_to",
          },
          {
            data: "sms_type",
          },
          {
            data: "action_link",
          },
        ],
        columnDefs: [{ targets: [5], orderable: false }],
      });

    $("#websitesales")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        order: [[0, "desc"]],
        ajax: {
          url: axios.defaults.baseURL + "getsmswebsitesaleshistory",
          type: "POST",
          data: function (d) {
            d.duration = v.filterSearch.duration;
            d.from = v.filterSearch.from;
            d.to = v.filterSearch.to;
          },
          headers: {
            Authorization: "Bearer " + v.$storage.get("auth").token,
          },
        },
        columns: [
          {
            data: "date",
          },
          {
            data: "time",
          },
          {
            data: "sms_from",
          },
          {
            data: "sms_to",
          },
          {
            data: "sms_type",
          },
          {
            data: "action_link",
          },
        ],
        columnDefs: [{ targets: [5], orderable: false }],
      });

    $("#websiteservice")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        order: [[0, "desc"]],
        ajax: {
          url: axios.defaults.baseURL + "getsmswebservicehistory",
          type: "POST",
          data: function (d) {
            d.duration = v.filterSearch.duration;
            d.from = v.filterSearch.from;
            d.to = v.filterSearch.to;
          },
          headers: {
            Authorization: "Bearer " + v.$storage.get("auth").token,
          },
        },
        columns: [
          {
            data: "date",
          },
          {
            data: "time",
          },
          {
            data: "sms_from",
          },
          {
            data: "sms_to",
          },
          {
            data: "type",
          },
          {
            data: "sms_type",
          },
          {
            data: "action_link",
          },
        ],
        columnDefs: [{ targets: [5], orderable: false }],
      });

    $("tbody").on("click", ".service", function () {
      $(this).toggleClass("stng sting");
    });
    $("tbody").on("click", ".sale", function () {
      $(this).toggleClass("doller dollbg");
    });
	$("tbody").on("click", ".prev-leads", function () {
      var id = $(this).data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        v.getMessageDeatils(id); 
      }
    });
  },
};
</script>

<style scoped>
a.call-modal {
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #ffffff;
  background: #3151a1;
  border-radius: 20px;
  padding: 6px 20px;
  margin-bottom: 15px;
}

.call-setting .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: #dbe5ff;
  font-weight: 600;
  font-size: 16px;
  border-radius: 10px 10px 0px 0px;
  margin-right: 10px;
  color: #3151a1;
}

.call-setting .nav-tabs .nav-item.show .nav-link,
.call-setting .nav-tabs .nav-link.active {
  color: #3151a1;
  font-weight: 600;
  font-size: 16px;
  background-color: #fff !important;
  border-color: #fff #fff #fff;
  border-bottom: 2px solid #3151a1;
}

.call-setting .nav-tabs {
  border-bottom: 1px solid transparent;
  position: relative;
}

.call-setting .tab-content {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 25px;
  border-top-left-radius: 0;
}

a.crebit {
  display: inline-block;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #ffffff;
  background: #3151a1;
  border-radius: 20px;
  padding: 6px 20px;
}

.leads-table-area.call-table .dt-body-right {
  text-align: left !important;
}

.leads-table-area.history-table .dt-body-right {
  text-align: left !important;
}

.call-table td b {
  font-weight: 800;
  color: #6d778e;
}

.call-table td span {
  font-weight: 800;
  color: #3151a1;
}

.leads-table-area.history-table {
  background: transparent;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  border-radius: 0;
  margin-bottom: 0;
}

.history-table .dataTables_wrapper .dataTables_length {
  display: none;
}

.history-table .dataTables_wrapper .dataTables_filter {
  display: none;
}

a.history-export {
  text-decoration: none;
  display: inline-block;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(49, 81, 161, 0.08);
  border-radius: 30px;
  font-weight: 600;
  font-size: 14px;
  color: #3151a1;
  padding: 3px 15px;
}

.histoty-timeline {
  position: absolute;
  right: 15px;
  display: inline-block;
  z-index: 2;
}

.history-table td span {
  font-weight: 800;
  color: #3151a1;
}

.history-table td p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.5;
  color: #6d778e;
  margin-left: 40px;
}

.history-table td p.incoming {
  color: #19cd9d;
}

.history-table td p.misscall {
  color: #ea5757;
}

.history-table td a {
  display: inline-block;
  text-decoration: none;
  font-size: 12px;
  line-height: 1.5;
  color: #6d778e;
}
.timeline {
  margin-top: 4px;
  margin-bottom: 20px;
}
.opens {
  display: inline-block;
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  background: #19cd9d;
  padding: 5px 14px;
  border-radius: 3px;
  text-decoration: underline;
  text-align: center;
  width: 90px;
}

@import "../../assets/css/dataTables.css";
</style>